<template>
  <div style="background: #a52321;" v-title data-title="Tischreservierung | Aaden POS">
    <div style="min-height: 100vh;display: flex;justify-content: center; align-items: center;padding: 4%;">
      <div v-if="confirmCancel">
        <div class="card" >
          <h2 style="font-size: x-large;word-break: break-all;">✔️ Successfully Returned</h2>
          <div class="row" style="margin-top: 32px;">
            <div class="label">Store Information</div>
          </div>
          <div style="display: flex;justify-content: space-between;font-size: 14px; align-items: center; border-bottom: none; " class="row">
            <div>
              <div style="font-weight: bold;font-size: 16px;" class="line">{{shopDetailInfo.shopName}}</div>
              <div style="margin-top:16px;"  class="line">
                <a
                    style="color: black;"
                    :href="shopAddress">
                  🏠  {{shopDetailInfo.shopAddress}}</a>

              </div>
              <div class="line"><a style="color: black;" :href="shopTel">☎️ {{shopDetailInfo.shopPhone}}</a></div>
              <div class="line">📧  {{shopDetailInfo.shopEmail}}</div>

            </div>
          </div>
          <div class="row" style="margin-top: 64px;">
            <b>Welcome to you next time!</b>
          </div>

        </div>
      </div>
      <div v-else>
        <div class="card">
          <div v-if="loading">
            <div style="margin: auto;width: 20%;padding: 100px;">
              <div class="loader">
              </div>
            </div>
          </div>
          <div v-else>
            <div>
              <h2 style="font-size: x-large;">❗️ Are you sure to cancel your reservation?</h2>
              <div class="row" style="margin-top: 32px;">
                <div class="label">Store Information</div>
              </div>
              <div style="display: flex;justify-content: space-between;font-size: 14px; align-items: center; border-bottom: none; " class="row">
                <div>
                  <div style="font-weight: bold;font-size: 16px;" class="line">{{shopDetailInfo.shopName}}</div>
                  <div style="margin-top:16px;"  class="line">
                    <a
                        style="color: black;"
                        :href="shopAddress">
                      🏠  {{shopDetailInfo.shopAddress}}</a>

                  </div>
                  <div class="line"><a style="color: black;" :href="shopTel">☎️ {{shopDetailInfo.shopPhone}}</a></div>
                  <div class="line">📧  {{shopDetailInfo.shopEmail}}</div>

                </div>
              </div>
              <div class="row" style="margin-top: 64px;">
                <b>Welcome to you next time!</b>
              </div>
              <div style="width: 100%;margin-top: 16px;">
                <button style="border-radius: 30px;width: 100%;height: 40px;background-color: #a52321;color: white;box-shadow: none;outline: none" type="submit" @click="sureToCancel">Cancel My Reservation</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hillo from 'hillo'
import IKUtils from 'innerken-js-utils'
export default {
  name: 'SuccessCancel',
  data () {
    return {
      loading: false,
      confirmCancel: false,
      shopTel: '',
      shopAddress: '',
      shopDetailInfo: null,
    }
  },
  async mounted(){
    const userId = IKUtils.getQueryString("userId")
    this.shopDetailInfo = (await hillo.post('https://reservoir.aaden.io/mutlipleUser/user/getDetail?id=' + userId, {})).data
    this.shopAddress = this.shopDetailInfo.mapUrl
    this.shopTel = 'tel:' + this.shopDetailInfo.shopPhone
  },
  methods: {
    async sureToCancel() {
      this.loading = true
      const token = IKUtils.getQueryString("token")
      if(this.$route.query.token !== ''){
        await hillo.jsonPost('https://reservoir.aaden.io/mutlipleUser/reservation/customerCancelPost', {
          token: token
        })
      }else {
        alert('error')
      }
      this.loading = false
      this.confirmCancel = true
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card{
  border-radius: 24px;
  background: white;
  max-width: 496px;
  flex-shrink: 1;
  width: 350px;
  margin: auto;
  padding: 24px;
}
.row{
  display: flex;
  justify-content: space-between;
  padding: 8px 0 12px 0;
  border-bottom: 1px dashed gainsboro;
  margin-top: 16px;
}
.row .label{
  font-weight: bold;
}
.line{
  margin-top: 2px;
  padding: 2px 0;
  text-decoration: underline
}
table {margin:auto;}

.flower-spinner,  .flower-spinner * {
  box-sizing: border-box;
}

.flower-spinner {
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flower-spinner .dots-container {
  height: calc(70px / 7);
  width: calc(70px / 7);
}

.flower-spinner .smaller-dot {
  background: #ff1d5e;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  animation: flower-spinner-smaller-dot-animation 2.5s 0s infinite both;
}

.flower-spinner .bigger-dot {
  background: #ff1d5e;
  height: 100%;
  width: 100%;
  padding: 10%;
  border-radius: 50%;
  animation: flower-spinner-bigger-dot-animation 2.5s 0s infinite both;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #514b82;
  animation:
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear;
}
@keyframes l20-1{
  0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
  12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
  25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
  50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
  100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}
</style>
