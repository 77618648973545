import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from '@/router';
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})

new Vue({
  render: h => h(App),
  router,
  axios
}).$mount('#app')
