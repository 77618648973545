import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import SuccessCancel from "@/page/SuccessCancel/SuccessCancel";

export default new VueRouter({
    routes:[
        {
            path:"/SuccessCancel",
            component:SuccessCancel
        },
        {
            path:'*',
            redirect:"/SuccessCancel"
        }
    ]
})
